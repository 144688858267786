import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Ourclients = () => {
    const options = {
        loop: true,
        margin: 20,
        items: 4,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        smartSpeed: 900,

        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            700: {
                items: 3
            },
            1000: {
                items: 4
            }
        }
    };
    return (
        <>


            {/* <div className="logo-main-div">
                <div className="container">
                    <div className="main-inner">
                        <div className="title-main">
                            <h2>
                                <span className="size-title">Our-Clients </span>
                                <br /><span className="size-title1">Trust is the foundation of every successful assets.</span>
                            </h2>
                        </div>

                        <div className="slider-container">
                            <div className="slider-track">
                                <div className="client-logo">
                                    <img src="assents/img/sagar.jpg" alt="sagar" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/gogad.jpg" alt="gogad" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/NTM.jpg" alt="NTM" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/SGF.PNG" alt="SGF" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/shah.jpg" alt="shah" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/sagar.jpg" alt="gogad" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/gogad.jpg" alt="NTM" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/NTM.jpg" alt="SGF" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/SGF.PNG" alt="gogad" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/shah.jpg" alt="shah" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/sagar.jpg" alt="gogad" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/gogad.jpg" alt="NTM" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/NTM.jpg" alt="SGF" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/SGF.PNG" alt="gogad" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/shah.jpg" alt="shah" />
                                </div>


                                <div className="client-logo">
                                    <img src="assents/img/sagar.jpg" alt="gogad" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/gogad.jpg" alt="NTM" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/NTM.jpg" alt="SGF" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/SGF.PNG" alt="gogad" />
                                </div>
                                <div className="client-logo">
                                    <img src="assents/img/shah.jpg" alt="shah" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="ourclient-main">
                <div className="container">
                    <div className="ourclient-inner-main">
                        <div className="ourclient-child">
                            <div className="ourclient-inner-child">
                                <p className="client-head">Our-Clients</p>
                                <p className="client-small">Trust is the foundation of every successful assets.</p>
                            </div>
                        </div>
                        <div className="ourclient-child">
                            <div className="ourclient-inner-child">
                                <OwlCarousel className='owl-theme' {...options}>
                                    <div class='item'>
                                        <a href="#">  <img src="assents/img/subhash.png" alt="gogad" /></a>
                                    </div>
                                    <div class='item'>
                                        <a href="#">  <img src="assents/img/manish.jpg" alt="gogad" /></a>
                                    </div>
                                    <div class='item'>
                                        <a href="#">  <img src="assents/img/Addon.png" alt="gogad" /></a>
                                    </div>
                                    <div class='item'>
                                        <a href="#">  <img src="assents/img/suryajyoti.jpg" alt="gogad" /></a>
                                    </div>
                                    <div class='item'>
                                        <a href="#">  <img src="assents/img/gogad.jpg" alt="gogad" /></a>
                                    </div>
                                    <div class='item'>
                                        <a href="#"> <img src="assents/img/NTM.jpg" alt="ntm" /></a>
                                    </div>
                                    <div class='item'>
                                        <a href="#"> <img src="assents/img/sagar.jpg" alt="sagar" /></a>
                                    </div>
                                    <div class='item'>
                                        <a href="#"> <img src="assents/img/SGF.png" alt="SGF" /></a>
                                    </div>
                                    <div class='item'>
                                        <a href="#"> <img src="assents/img/shah.jpg" alt="shah" /></a>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Ourclients;
